import { createStore } from 'vuex'
import { User } from '@/store/modules/User'
import { Receipt } from '@/store/modules/Receipt'


/* export default createStore({
	modules: { User }
}) */

const store = createStore({
  state() {
    return {
      memories: [
        {
          id: 'm1',
          image:
            'https://hips.hearstapps.com/hmg-prod/images/alpe-di-siusi-sunrise-with-sassolungo-or-langkofel-royalty-free-image-1623254127.jpg',
          title: 'A trip into the mountains',
          description: 'It was really nice trip!'
        },
        {
          id: 'm2',
          image:
            'https://i.pinimg.com/originals/04/c3/33/04c33325cf3ddf9af627d921d655eff6.jpg',
          title: 'Surfing the sea side',
          description: 'It was really nice trip!'
        },
        {
          id: 'm3',
          image:
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxY5yo61QMJ2GXDMjmSm7z8h_9IWjmnr6nNw&usqp=CAU',
          title: 'Good eating',
          description: 'It was really nice trip!'
        }
      ]
    }
  },
  mutations: {
    addMemory(state, memoryData) {
      const newMemory = {
        id: new Date().toISOString(),
        title: memoryData.title,
        image: memoryData.imageUrl,
        description: memoryData.description
      }

      state.memories.unshift(newMemory)
    }
  },
  actions: {
    addMemory(context, memoryData) {
      context.commit('addMemory', memoryData)
    }
  },
  getters: {
    memories(state) {
      return state.memories
    },
    memory(state) {
      return (memoryId) => {
        return state.memories.find((memory) => memory.id === memoryId)
      }
    }
  },
  modules: {
    User,
    Receipt
  }
})

export default store
