import { postLogin, getInfo, postLogout } from '@/api/login/login'
import { getToken, setToken, removeToken } from '@/utils/auth'

export const Receipt = {
  namespaced: true,

  state: () => ({
    id: 6,
    order_nm: undefined,
    order_tel_no: undefined,
    bank_nm: undefined,
    acc_nm: undefined,
    acc_num: undefined,
    product: undefined,
    imei: undefined,
    file1_id: undefined,
    manufactur: undefined,
    series: undefined,
    modelName: undefined,
    volumn: undefined,
    grade1_price: 0,
    grade1: undefined,
    grade1Detail: undefined
    }),

  mutations: {
    SET_ID: (state, name) => {
      state.id = name
    },
    SET_ORDER_NM: (state, name) => {
      state.order_nm = name
    },
    SET_ORDER_TEL_NO: (state, name) => {
      state.order_tel_no = name
    },
    SET_BANK_NM: (state, name) => {
      state.bank_nm = name
    },
    SET_ACC_NM: (state, name) => {
      state.acc_nm = name
    },
    SET_ACC_NUM: (state, name) => {
      state.acc_num = name
    },
    SET_PRODUCT: (state, name) => {
      state.product = name
    },
    SET_IMEI: (state, payload) => {
      state.imei = payload.imei
      state.file1_id = payload.file1_id
    },
    SET_MANUFACTUR: (state, name) => {
      state.manufactur = name
    },
    SET_SERIES: (state, name) => {
      state.series = name
    },
    SET_MODELNAME: (state, name) => {
      state.modelName = name
    },
    SET_VOLUMN: (state, name) => {
      state.volumn = name
    },
    SET_GRADE1: (state, name) => {
      state.grade1 = name
    },
    SET_GRADE1_DETAIL: (state, name) => {
      state.grade1Detail = name
    },
    SET_GRADE1_PRICE: (state, name) => {
      state.grade1_price = name
    }
  },

  actions: {
    setPrtnrInfo({ commit }, prtnrInfo){
      commit('SET_ID', prtnrInfo.id)
      commit('SET_ORDER_NM', prtnrInfo.order_nm)
      commit('SET_ORDER_TEL_NO', prtnrInfo.order_tel_no)
      commit('SET_BANK_NM', prtnrInfo.bank_nm)
      commit('SET_ACC_NM', prtnrInfo.acc_nm)
      commit('SET_ACC_NUM', prtnrInfo.acc_num)
    },
    setId({ commit }, name) {
      commit('SET_ID', name)
    },
    setOrderNm({ commit }, name) {
      commit('SET_ORDER_NM', name)
    },
    setOrderTelNo({ commit }, name) {
      commit('SET_ORDER_TEL_NO', name)
    },
    setBankNm({ commit }, name) {
      commit('SET_BANK_NM', name)
    },
    setAccNM({ commit }, name) {
      commit('SET_ACC_NM', name)
    },
    setAccNum({ commit }, name) {
      commit('SET_ACC_NUM', name)
    },
    setProduct({ commit }, name) {
      commit('SET_PRODUCT', name)
    },
    setImei({ commit }, payload) {
      commit('SET_IMEI', payload)
    },
    setManuFactur({ commit }, name) {
      commit('SET_MANUFACTUR', name)
    },
    setSeries({ commit }, name) {
      commit('SET_SERIES', name)
    },
    setModelName({ commit }, name) {
      commit('SET_MODELNAME', name)
    },
    setVolumn({ commit }, name) {
      commit('SET_VOLUMN', name)
    },
    setGrade1({ commit }, name) {
      commit('SET_GRADE1', name)
    },
    setGrade1Detail({ commit }, name) {
      commit('SET_GRADE1_DETAIL', name)
    },
    setGrade1Price({ commit }, name) {
      commit('SET_GRADE1_PRICE', name)
    },
    
    getReceipt(state) {
      return state
    }
  }
}
