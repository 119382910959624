import { createRouter, createWebHistory } from '@ionic/vue-router'

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import('@/views/Main.vue')
  },
  {
    path: '/pickup',
    name: 'pickup',
    component: () => import('@/views/Pickup.vue')
  },
  {
    path: '/pickup/:id',
    name: 'pickupDetail',
    component: () => import('@/views/PickupDetail.vue')
  },
  {
    path: '/deposit',
    name: 'deposit',
    component: () => import('@/views/Deposit.vue')
  },
  {
    path: '/deposit-target',
    name: 'depositTarget',
    component: () => import('@/views/DepositTarget.vue')
  },
  {
    path: '/pickup-state',
    name: 'pickupState',
    component: () => import('@/views/PickupState.vue')
  },
  {
    path: '/collection-detail',
    name: 'collectionDetail',
    component: () => import('@/views/CollectionDetail.vue')
  },
  {
    path: '/agency-list',
    name: 'agencyList',
    component: () => import('@/views/AgencyList.vue')
  },
  {
    path: '/agency-edit/:id',
    name: 'agencyEdit',
    component: () => import('@/views/AgencyRegist.vue')
  },
  {
    path: '/agency-regist',
    name: 'agencyRegist',
    component: () => import('@/views/AgencyRegist.vue')
  },
  {
    path: '/input-imei',
    name: 'inputImei',
    component: () => import('@/views/InputImei.vue')
  },
  {
    path: '/device-info',
    name: 'deviceInfo',
    component: () => import('@/views/DeviceInfo.vue')
  },
  {
    path: '/inspect-grade',
    name: 'inspectGrade',
    component: () => import('@/views/InspectGrade.vue')
  },
  {
    path: '/enter-amount',
    name: 'enterAmount',
    component: () => import('@/views/EnterAmount.vue')
  },
  {
    path: '/collection-final',
    name: 'collectionFinal',
    component: () => import('@/views/CollectionFinal.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Login.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes 
})

export default router
